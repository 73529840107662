import apiClient from '@/clients/apiClient';

export const signIn = (data) => apiClient.post('auth/login', data);

export const signInWithMfa = (data) => apiClient.post('auth/login/mfa', data);

export const requestReset = (data) =>
  apiClient.post('auth/request-reset', data);

export const resetPassword = (data) =>
  apiClient.post('auth/reset-password', data);

export const setupMfa = (data) => apiClient.post('auth/mfa/setup', data);

export const enableMfa = (data) => apiClient.post('auth/mfa/enable', data);

export const logUserOut = (data) => apiClient.post('auth/logout', data);

export const getAllPermissions = () => apiClient.get('auth/permissions');
