import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

export const formatDate = (value, format) => {
  const date = dayjs(value);

  if (!date.isValid()) return '--';

  return date.format(format ? format : 'MM/DD/YYYY');
};

export const humanReadableDate = (value) => {
  if (value) {
    return dayjs(String(value)).format('MMMM Do, YYYY, h:mma');
  }
};

export const humanReadableDateWithoutTime = (value) => {
  if (value) {
    return dayjs(String(value)).format('Do MMM, YYYY');
  }
};

export const formatTime = (value) => {
  if (value) {
    return dayjs(String(value)).format('h:mma');
  }
};

export const formatDay = (value) => {
  if (value) {
    return dayjs(String(value)).format('MMMM Do, YYYY');
  }
};

export const formatDayOnly = (value) => {
  if (value) {
    return dayjs(String(value)).format('Do MMMM');
  }
};

export const formatPhone = (value) => {
  if (value) {
    value = value.replace(/\D|(0+|2340|234)/gi, '');
    value = value.replace(/(\d{3})(\d{3})(\d{4})/gi, '+234 $1 $2 $3');
    return value;
  }
};

export const formatNumber = (value) => {
  if (value) {
    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }
  return value;
};

export const censorBVN = (value) => {
  if (value) {
    let characters = value.split('');
    return [...characters].slice(0, 7).join('') + '*'.repeat(value.length - 7);
  }
};

export const padValue = (value) => {
  if (value) {
    return String(value).padStart(5, '0');
  }
};

export const isNullOrEmpty = (value) => {
  return value !== null && value !== undefined && value.length > 0;
};

export const getCompanyStatusDisplayText = (status) => {
  switch (status) {
    case 'AwaitingReverification':
    case 'AwaitingVerification':
      return 'Pending';
    case 'Verified':
      return 'Verified';
    case 'Deactivated':
      return status;
    default:
      return status;
  }
};

export const monthInThePast = (numberToSubtract, timePeriod) => {
  return dayjs().subtract(numberToSubtract, timePeriod).format('MMMM');
};

export const getPeriodDifference = (value, diffPeriod = null) => {
  const now = dayjs();
  const differenceInYears = now.diff(value, 'year');
  const differenceInMonths = now.diff(value, 'month');
  const differenceInDays = now.diff(value, 'day');

  if (diffPeriod !== null) {
    switch (diffPeriod) {
      case 'months':
        return differenceInMonths;
      case 'days':
        return differenceInDays;
      case 'years':
        return differenceInYears;
    }
  } else {
    if (differenceInYears > 1) {
      return `${differenceInYears} years`;
    } else if (differenceInMonths > 1) {
      return `${differenceInMonths} months`;
    } else {
      return `${differenceInDays} days`;
    }
  }
};

export const formatPascalCaseText = (value) =>
  value.split(/(?=[A-Z])/).join(' ');
