import apiClient from '@/clients/apiClient';

export const getStaffList = (
  page = 1,
  pageSize = 10,
  search = '',
  sortBy = '',
  orderBy = ''
) =>
  apiClient.get(
    `staff?Page=${page}&PageSize=${pageSize}&Search=${search}&Sort.Sort_By=${sortBy}&Sort.Order_By=${orderBy}`
  );

export const getUnpaginatedStaffList = (search = '') =>
  apiClient.get(`staff/lite?Search=${search}`);

export const getStaffGroupList = () => apiClient.get(`staff/groups`);

export const createGroup = (data) => apiClient.post('staff/groups', data);

export const getSingleStaff = (id) => apiClient.get(`staff/${id}`);

export const createStaff = (data) => apiClient.post('staff', data);

export const changePassword = (data) =>
  apiClient.post('staff/current/change-password', data);

export const changeStaffPassword = (payload) =>
  apiClient.post(`staff/current/change-password`, payload);

export const getCurrentStaff = () => apiClient.get('staff/current');

export const updateCurrentStaff = (data) =>
  apiClient.put('staff/current', data);

export const updateCurrentStaffProfilePhoto = (data) =>
  apiClient.post('staff/current/profile-photo', data);

export const getStaffById = (staffId) => apiClient.get(`staff/${staffId}`);

export const forceResetStaffPassword = (staffId) => {
  apiClient.post(`staff/${staffId}/force-password-reset`);
};

export const updateStaffMember = (payload) =>
  apiClient.put(`staff/${payload.staffId}`, payload.data);

export const updateStaffMemberProfilePhoto = (staffId, payload) =>
  apiClient.post(`staff/${staffId}/profile-photo`, payload);

export const suspendOrUnsuspendStaff = (payload) =>
  apiClient.post(`staff/${payload.staffId}/${payload.suspensionType}`);

export const getAllRoles = () => apiClient.get(`staff/roles`);

export const createRole = (data) => apiClient.post('staff/roles', data);

export const getRoleById = (roleId) => apiClient.get(`staff/roles/${roleId}`);

export const editRole = (payload) => apiClient.put(`staff/roles/${payload.roleId}`, payload.data);

export const deleteRole = (roleId) => {
  return apiClient.delete(`staff/roles/${roleId}`);
};
