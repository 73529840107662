import { staffServices } from '@/services';

const {
  getStaffList,
  getUnpaginatedStaffList,
  getSingleStaff,
  getStaffGroupList,
  createGroup,
  createStaff,
  changePassword,
  changeStaffPassword,
  getCurrentStaff,
  getStaffById,
  forceResetStaffPassword,
  updateCurrentStaff,
  updateCurrentStaffProfilePhoto,
  updateStaffMember,
  updateStaffMemberProfilePhoto,
  suspendOrUnsuspendStaff,
  getAllRoles,
  createRole,
  getRoleById,
  editRole,
  deleteRole,
} = staffServices;

export default {
  namespaced: true,
  state: () => ({
    staffList: [],
    staffGroupList: [],
    staffRoleList: [],
    currentRole: null,
  }),
  getters: {
    staffList: ({ staffList }) => staffList,
    staffGroupList: ({ staffGroupList }) => staffGroupList,
    staffRoleList: ({ staffRoleList }) => staffRoleList,
    currentRole: ({ currentRole }) => currentRole,
  },
  mutations: {
    SET_STAFF_LIST: (state, data) => {
      state.staffList = data;
    },
    SET_STAFF_GROUP_LIST: (state, data) => {
      state.staffGroupList = data;
    },
    SET_STAFF_ROLE_LIST: (state, data) => {
      state.staffRoleList = data;
    },
    SET_CURRENT_ROLE_LIST: (state, data) => {
      state.currentRole = data;
    },
  },
  actions: {
    async getStaffList({ commit, dispatch }, query) {
      try {
        const { data } = await getStaffList(
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || '',
          query?.sortBy || '',
          query?.orderBy || ''
        );

        commit('SET_STAFF_LIST', data.data);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getStaffGroupList({ commit, dispatch }) {
      try {
        const { data } = await getStaffGroupList();
        commit('SET_STAFF_GROUP_LIST', data.data);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getSingleStaff({ dispatch }, id) {
      try {
        const { data } = await getSingleStaff(id);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async createGroup({ dispatch }, group) {
      try {
        const response = await createGroup(group);

        if ([200, 201].includes(response.status)) {
          dispatch(
            'notifications/displayNotification',
            {
              message: `Created group: ${response?.data.name}`,
              type: 'success',
            },
            { root: true }
          );
        }

        return response?.data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async createStaff({ dispatch }, staff) {
      try {
        const response = await createStaff(staff);
        if (response?.status === 201) {
          dispatch(
            'notifications/displayNotification',
            { message: `Added ${response?.data?.firstName}`, type: 'success' },
            { root: true }
          );
        }

        return response?.data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async changePassword({ dispatch }, details) {
      try {
        const data = await changePassword(details);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: `Password updated`, type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getUnpaginatedStaffList({ dispatch }, query) {
      try {
        const { data } = await getUnpaginatedStaffList(query?.search || '');

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async changeStaffPassword({ dispatch }, payload) {
      try {
        const data = await changeStaffPassword(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message: 'Password updated successfully',
              type: 'success',
            },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCurrentStaff({ dispatch }) {
      try {
        const { data } = await getCurrentStaff();

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getStaffById({ dispatch }, staffId) {
      try {
        const { data } = await getStaffById(staffId);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async forceResetStaffPassword({ dispatch }, staffId) {
      try {
        const data = await forceResetStaffPassword(staffId);

        if (data.status === 204) {
          dispatch(
            'notifications/displayNotification',
            {
              message: 'Password reset email sent',
              type: 'success',
            },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async updateCurrentStaff({ dispatch }, payload) {
      try {
        const data = await updateCurrentStaff(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Profile updated', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async updateCurrentStaffProfilePhoto({ dispatch }, data) {
      try {
        const response = await updateCurrentStaffProfilePhoto(data);

        if (response.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message: 'Your profile picture has been successfully updated',
              type: 'success',
            },
            { root: true }
          );
        }

        return response;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'error' },
          { root: true }
        );
      }
    },
    async suspendOrUnsuspendStaff({ dispatch }, payload) {
      try {
        const data = await suspendOrUnsuspendStaff(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message: `Staff account ${
                payload.suspensionType === 'suspend'
                  ? 'suspended'
                  : 'reactivated'
              }`,
              type: 'success',
            },
            { root: true }
          );
        }
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async updateStaffMember({ dispatch }, payload) {
      try {
        const data = await updateStaffMember(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Staff profile updated', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async updateStaffMemberProfilePhoto({ dispatch }, payload) {
      try {
        const response = await updateStaffMemberProfilePhoto(
          payload.staffId,
          payload.data
        );

        if (response.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message: "Staff's profile picture has been successfully updated",
              type: 'success',
            },
            { root: true }
          );
        }

        return response;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'error' },
          { root: true }
        );
      }
    },
    async getAllRoles({commit, dispatch }) {
      try {
        const { data } = await getAllRoles();
        commit('SET_STAFF_ROLE_LIST', data);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async createRole({ dispatch }, staffRole) {
      try {
        const response = await createRole(staffRole);
        if (response?.status === 201) {
          dispatch(
            'notifications/displayNotification',
            { message: `Added ${response?.data?.name}`, type: 'success' },
            { root: true }
          );
        }

        return response?.data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    
    async getRoleById({ dispatch }, roleId) {
      try {
        const { data } = await getRoleById(roleId);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async editRole({ dispatch }, payload) {
      try {
        const { data } = await editRole(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: `Role updated successfully`, type: 'success' },
            { root: true }
          );
        }
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async deleteRole({ dispatch }, roleId) {
      try {
        const { data } = await deleteRole(roleId);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
        throw error;
      }
    },
  },
};
